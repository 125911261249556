import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

import {
    getColumnDefs,
    getDataArray,
    getDataArrayProcessed,
    getUsers,
    getUsersArrayProcessed
} from '../../redux/selectors/resourceSelectors';
import {
    getAuth0token,
    getCommunity,
    getDataEditorValidation,
    getUploadObjects,
} from '../../redux/selectors/mainSelectors';
import {setAgGridDataArray, setUsers} from '../../redux/actions/resourceActions';
import AgGridValidator from '../../utils/AgGridValidator';

import '../../styles/ag-grid-extra.css';
import SimpleEditor from '../molecules/editors/SimpleEditor';
import AsyncValidationEditor from '../molecules/editors/AsyncValidationEditor';
import SuppliersEditor from '../molecules/editors/SuppliersEditor';
import { getEnableExternalValidations, getExternalValidationsLoading } from '../../redux/selectors/validationSelectors';
import ExternalAsyncValidator from '../../utils/ExternalAsyncValildator';
import Button from '@material-ui/core/Button';
import HazardsEditor from '../molecules/editors/HazardsEditor';
import ProductsEditor from '../molecules/editors/ProductsEditor';
import OriginsEditor from '../molecules/editors/OriginsEditor';
import { setLoadingExternalValidations } from '../../redux/actions/validationActions';
import { useSnackbar } from 'notistack';
import {TextField} from "@material-ui/core";
import CancelButton from "../molecules/CancelButton";
import AddUserInput from "../molecules/AddUserInput";

const DataEditor = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const auth0token = useSelector(getAuth0token);

    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const [user, setUser] = useState(null)

    const loadingVerifications = useSelector(getExternalValidationsLoading);

    // have been inited on Mapper =selectedCols> onNext()
    const objectType = useSelector(getUploadObjects);
    const community = useSelector(getCommunity);
    const colDefs = useSelector(getColumnDefs);
    const enableExternalValidations = useSelector(getEnableExternalValidations);
    const dataArrayProcessed = useSelector(getDataArrayProcessed)
    const users = useSelector(getUsers)

    const onGridReady = params => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        //window.aggrid = params; //debugging purposes
        if (enableExternalValidations) {
            dispatch(setLoadingExternalValidations('+1'));
            ExternalAsyncValidator(auth0token, objectType, colDefs, dataArrayProcessed, dispatch).then(hasValidation => {
                dispatch(setLoadingExternalValidations('-1'));
                if (hasValidation) {
                    // do something
                }
            });
        }
    };

    useEffect(() => {
        if (gridApi && loadingVerifications === 0) {
            gridApi.refreshCells({ force: true });
        }
    }, [loadingVerifications]);
    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        editable: true,
        community,
        objectType,
    };

    const valueSetter = params => {
        const rowIndex = params.node.childIndex;
        const columnName = params.column.colId;
        const rowData = params.data;
        const value = params.newValue;
        dispatch(setAgGridDataArray(rowIndex, columnName, rowData, value));
        return false;
    };

    const cellClass = function(params) {
        if (!AgGridValidator(params, dispatch)) {
            // for the async validator search for ExternalAsyncValidator
            return 'invalid-cell';
        } else {
            return null;
        }
    };

    const CustomCellRenderer = params => {
        const handleOriginalValue = () => {
            const { originalValue } = params.node.data[params.column.colId];
            params.node.setDataValue(params.column.colId, { originalValue, displayValue: originalValue, hasBeenEdited: true });
        };

        const handleApplyToAll = () => {
            let changedCells = -1;
            params.api.forEachNode(node => {
                if (node.data[params.column.colId] && node.data[params.column.colId].displayValue) {
                    if (node.data[params.column.colId].originalValue && node.data[params.column.colId].originalValue === params.value.originalValue) {
                        node.setDataValue(params.column.colId, params.value);
                        changedCells++;
                    } else if (node.data[params.column.colId].displayValue === params.value.originalValue) {
                        node.setDataValue(params.column.colId, params.value);
                        changedCells++;
                    }
                }
            });
            enqueueSnackbar(`${changedCells} cells were affected`, { variant: 'warning', autoHideDuration: 3000 });
        };
        // check the data exists, to avoid error
        if (params.value && params.value.invalid)
            return <span className="invalid-cell">{params.value.displayValue}</span>;
        else if (params.value && params.value.hasBeenEdited)
            return (
                <span style={{ color: '#526c91', fontSize: '10px'}}>
                    {params.value.originalValue} : <span style={{ color: 'black', fontSize: '12px'}}>{params.value.displayValue}</span>
                    <Button size={'small'} variant={'outlined'} onClick={handleOriginalValue}>original value</Button>
                    <Button size={'small'} variant={'outlined'} onClick={handleApplyToAll}>apply to all</Button>
                </span>
            );
        else if (params.value)
            return <span>{params.value.displayValue}</span>;
        else return null;
    };

    const frameworkComponents = {
        simpleEditor: SimpleEditor,
        asyncValidationEditor: AsyncValidationEditor,
        suppliersEditor: SuppliersEditor,
        hazardsEditor: HazardsEditor,
        productsEditor: ProductsEditor,
        originsEditor: OriginsEditor,
        customCellRenderer: CustomCellRenderer,
    };
    

    return (
        <LoadingOverlay active={loadingVerifications !== 0} spinner text="Please wait, validating content...">
            <AddUserInput/>
            <div className="ag-theme-material" style={{ height: '600px' }}>
                <AgGridReact
                    rowSelection="multiple"
                    reactNext
                    enableColResize
                    columnDefs={colDefs}
                    rowData={dataArrayProcessed}
                    frameworkComponents={frameworkComponents}
                    getRowNodeId={data => data.id}
                    defaultColDef={{ ...defaultColDef, valueSetter, cellClass, cellRenderer: 'customCellRenderer' }}
                    onGridReady={rf => onGridReady(rf)}
                    suppressScrollOnNewData={true}
                />
            </div>
        </LoadingOverlay>
    );
};

export default DataEditor;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormAsyncErrors, getFormSyncErrors, getFormValues, submit } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import HeaderContentsFooterTemplate from '../components/templates/HeaderContentsFooterTemplate';
import MetaFormEditor from '../components/organisms/MetaFormEditor';
import {setMetadata, setUserStats} from '../redux/actions/mainActions';
import { METADATA_STEP_NAME } from '../EN_Texts';
import { getAuth0token, getCommunity, getFoodakaiEnv, getJsonForServer, getUploadMappings, getUploadMetadata } from '../redux/selectors/mainSelectors';
import ServerSendingDialog from '../components/molecules/ServerSendingDialog';
import { footstepValidation, getActiveStep, includesStep } from '../redux/selectors/stepsSelectors';
import { ROUTE_HOME } from '../ROUTES';
import composeCSVselectedCols from '../utils/composeCSVselectedCols';
import { getColumnDefs, getCurrentSheet, getDataArray, getFile, getUsers } from '../redux/selectors/resourceSelectors';
import { setValidationsByStep } from '../redux/actions/validationActions';
import { useAuth0 } from '../components/organisms/Auth0Wrapper';
import { formatDataForXLSX } from '../utils/AgGridDataFormatter';
import ProcessedFileDownloader from '../components/organisms/ProcessedFileDownloader';

const MetadataAndSendPage = () => {
    const dispatch = useDispatch();
    const form = useSelector(getFormValues(METADATA_STEP_NAME));
    const community = useSelector(getCommunity);
    const metadataStore = useSelector(getUploadMetadata);
    const currentSheet = useSelector(getCurrentSheet);
    const mappings = useSelector(getUploadMappings);
    const jsonForServer = useSelector(getJsonForServer);
    const currentStep = useSelector(getActiveStep);
    const file = useSelector(getFile);
    const hasEditorStep = useSelector(includesStep('editor'));
    const columnDefs = useSelector(getColumnDefs);
    const rowData = useSelector(getDataArray);
    const foodakai_env = useSelector(getFoodakaiEnv);
    const users = useSelector(getUsers);

    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth0();
    const accessToken = useSelector(getAuth0token);

    const [fields, setFields] = useState([]);
    const [currentSendingUserIndex, setCurrentSendingUserIndex] = useState();

    let hasError = false;
    let sendingUsersStatus = {};

    useEffect(() => {
        //GET METADATA FIELDS
        (async () => {
            let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/metadataFields?community=${community}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            let json = await response.json();
            console.log(json);
            if (json.status === 'ok') {
                let validations = json.data.filter(field => field.required).map(field => field.name);
                setFields(json.data);
                dispatch(setValidationsByStep(validations, currentStep.name));
                if (json.error_response && json.error_response.json) {
                    const err = await json.error_response.json();
                    console.log(err);
                }
            } else {
                const { message } = json;
                enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                if (json.error_response && json.error_response.json) {
                    const err = await json.error_response.json();
                    console.log(err);
                }
            }
        })();
    }, [accessToken, community, currentStep.name, dispatch, enqueueSnackbar]);

    // VALIDATE AND SEND
    const formSyncErrors = useSelector(getFormSyncErrors(METADATA_STEP_NAME));
    const formAsyncErrors = useSelector(getFormAsyncErrors(METADATA_STEP_NAME));
    const isValid =
        (!formSyncErrors || (Object.keys(formSyncErrors).length === 0 && formSyncErrors.constructor === Object)) &&
        (!formAsyncErrors || (Object.keys(formAsyncErrors).length === 0 && formAsyncErrors.constructor === Object));
    const [sending, setSending] = useState(false);
    const addUserToRowData = (user, dataArray) => {
        if (dataArray?.length && user) {
            const user_rows = dataArray.map(row => ({ ...row, user: { displayValue: user } }));
            return user_rows;
        } else {
            return null;
        }
    };

    const sendToServer = async (i, json1, cb, multiple_users) => {
        setCurrentSendingUserIndex(i);
        let formData = new FormData();
        let data;
        let columnDefinitions;
        //in case of file upload
        if (file) {
            if (community === 'foodakai') {
                if (columnDefs.find(column => column.headerName === 'user')) {
                    data = rowData;
                    columnDefinitions = columnDefs;
                } else {
                    columnDefinitions = [...columnDefs, { headerName: 'user', field: 'user' }];
                    data = addUserToRowData(users[i], rowData);
                }

                if (hasEditorStep)
                    formData.append(
                        'csv',
                        composeCSVselectedCols(
                            formatDataForXLSX({
                                columnDefs: columnDefinitions,
                                rowData: data,
                            }),
                        ),
                    );
                else formData.append('csv', composeCSVselectedCols(currentSheet, mappings));
            }
            formData.append('file', file);
        }
        //both in file and stream upload
        formData.append('json', JSON.stringify(json1));
        formData.append('apiKey', user['http://apiKey']);
        formData.append('foodakai_env', foodakai_env);
        formData.append('index', i);

        if (multiple_users) formData.append('user', users[i]);

        let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/sendCSV`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        let json = await response.json();

        if (json.status === 'ok') {
            if (multiple_users) sendingUsersStatus[users[i]] = { status: 'ok' };
            else sendingUsersStatus['all'] = { status: 'ok' };
        } else {
            // alert message
            const { message } = json;
            if (multiple_users) sendingUsersStatus[users[i]] = { status: 'err', error: message };
            else sendingUsersStatus['all'] = { status: 'err', error: message };
            hasError = true;
        }
    };

    const handleFinish = cb => {
        dispatch(setMetadata(form));
        //since dispatch is slow and async for an unexpected reason, we'll add the metadata to the json to be sent manually
        const json1 = {
            ...jsonForServer,
            metadata: form,
        };

        //open the sending modal
        setSending(true);
        //sendToServer
        if (isValid) {
            (async () => {
                hasError = false;
                sendingUsersStatus = {};
                if (users.length) {
                    for (let i = 0; i < users.length; i++) {
                        await sendToServer(i, json1, cb, true);

                        if (i === users.length - 1) {
                            if (!hasError) {
                                //setTimeout just to show the loader
                                setTimeout(() => {
                                    // run submit to run validations
                                    dispatch(submit(METADATA_STEP_NAME));
                                    // close the sending modal
                                    setSending(false);
                                    // run the callback given from finish button (go to next page etc.)
                                    if (cb) cb();
                                }, 1000);
                            } else {
                                enqueueSnackbar('There has been an error trying to upload, check console', {
                                    variant: 'error',
                                    autoHideDuration: 5000,
                                });
                                console.log(sendingUsersStatus);
                                if (cb) cb();
                            }
                        }
                    }
                } else {
                    await sendToServer(0, json1, cb, false);

                    if (!hasError) {
                        //setTimeout just to show the loader
                        setTimeout(() => {
                            // run submit to run validations
                            dispatch(submit(METADATA_STEP_NAME));
                            // close the sending modal
                            setSending(false);
                            // run the callback given from finish button (go to next page etc.)
                            if (cb) cb();
                        }, 1000);
                    } else {
                        enqueueSnackbar('There has been an error trying to upload, check console', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        });
                        console.log(sendingUsersStatus);
                        if (cb) cb();
                    }
                }
                dispatch(setUserStats(sendingUsersStatus));
            })();
        } else {
            // run submit to run validations
            dispatch(submit(METADATA_STEP_NAME));
            // close the sending modal
            setSending(false);
        }
    };

    const footstepsValid = useSelector(footstepValidation);
    if (footstepsValid)
        return (
            <HeaderContentsFooterTemplate onFinish={handleFinish}>
                <MetaFormEditor initialValues={metadataStore} fields={fields} onSubmit={() => {}} />
                <ProcessedFileDownloader columnDefs={columnDefs} rowData={rowData} jsonData={jsonForServer} />
                <ServerSendingDialog open={sending} currentSendingUserIndex={currentSendingUserIndex} users={users} />
            </HeaderContentsFooterTemplate>
        );
    else return <Redirect to={ROUTE_HOME} />;
};

export default MetadataAndSendPage;

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import Hidden from '@material-ui/core/Hidden';
import { Redirect, useParams } from 'react-router-dom';
import 'react-paginate/demo/styles/style.css'
import FancyButton from '../components/atoms/FancyButton';
import LogoContentsTemplate from '../components/templates/LogoContentsTemplate';
import { addFootstep, setFileSteps, setSteps, setStreamSteps } from '../redux/actions/stepsActions';
import { setValidations } from '../redux/actions/validationActions';
import FileManager from '../components/organisms/FileManager';
import Loader from '../components/molecules/Loader';
import { getUserItems } from '../redux/selectors/fileManagementSelectors';
import { deleteUserItem, setUserItems } from '../redux/actions/fileManagementActions';
import { set_foodakai_env, setAuth0TOken, setTheme } from '../redux/actions/mainActions';
import { useAuth0 } from '../components/organisms/Auth0Wrapper';
import { getAuth0token, getCommunity } from '../redux/selectors/mainSelectors';
import { ROUTE_LOGIN } from '../ROUTES';
import { getStepsList } from '../redux/selectors/stepsSelectors';
import ReactPaginate from 'react-paginate';

const HomePage = ({ history, params }) => {
    const { foodakai_env } = useParams();
    const dispatch = useDispatch();
    if (foodakai_env) dispatch(set_foodakai_env(foodakai_env));
    const steps = useSelector(getStepsList);
    const items = useSelector(getUserItems);
    const community = useSelector(getCommunity);
    const auth = useAuth0();
    const { user, getTokenSilently } = auth;
    const [userDataLoading, setUserDataLoading] = useState(false);
    const [serverResponded, setServerResponded] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [logo, setLogo] = useState();
    const [page, setPage] = useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const token = useSelector(getAuth0token); // the same as accessToken but used outside of the useEffect

    useEffect(() => {
        if (community) {
            //get token
            (async () => {
                const accessToken = await getTokenSilently({
                    audience: `https://www.foodakai.com`,
                });

                //GET THEME
                (async () => {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/theme?community=${community}`, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    const jsonResponse = await response.json();
                    if (jsonResponse.status === 'ok') {
                        dispatch(setTheme(jsonResponse.data));
                        setServerResponded(true);
                    } else {
                        if (jsonResponse.error_response && jsonResponse.error_response.json) {
                            const err = await jsonResponse.error_response.json();
                            console.log(err);
                        }
                    }
                })();
                //GET FIRST SCREEN DATA
                (async () => {
                    let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/entranceScreen?community=${community}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    let json = await response.json();
                    if (json.status === 'ok') {
                        setLogo(json.data.logo);
                        setTitle(json.data.title);
                        setText(json.data.text);
                    } else {
                        const { message } = json;
                        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                        if (json.error_response && json.error_response.json) {
                            const err = await json.error_response.json();
                            console.log(err);
                        }
                    }
                })();

                //GET VALIDATORS
                (async () => {
                    let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/validations?community=${community}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    let json = await response.json();
                    if (json.status === 'ok') {
                        dispatch(setValidations(json.data.validations));
                    } else {
                        const { message } = json;
                        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                        if (json.error_response && json.error_response.json) {
                            const err = await json.error_response.json();
                            console.log(err);
                        }
                    }
                })();

                //GET STEPS
                (async () => {
                    let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/steps?community=${community}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    let json = await response.json();
                    if (json.status === 'ok') {
                        dispatch(setSteps(json.data.fileSteps));
                        dispatch(setFileSteps(json.data.fileSteps));
                        dispatch(setStreamSteps(json.data.streamSteps));
                    } else {
                        const { message } = json;
                        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                        if (json.error_response && json.error_response.json) {
                            const err = await json.error_response.json();
                            console.log(err);
                        }
                    }
                })();
                // GET USER DATA
                (async () => {
                    setUserDataLoading(true);
                    let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/uploadedData?community=${community}&apiKey=${user['http://apiKey']}&page=${page}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    let json = await response.json();
                    if (json.status === 'ok') {
                        dispatch(setUserItems(json.data));
                        setUserDataLoading(false);
                    } else {
                        const { message } = json;
                        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                        if (json.error_response && json.error_response.json) {
                            const err = await json.error_response.json();
                            console.log(err);
                        }
                    }
                })();

                dispatch(setAuth0TOken(accessToken));
            })();
        }
    }, [community, dispatch, enqueueSnackbar, user]);

    useEffect(()=>{
         if (user && token ){
                (async () => {
                    setUserDataLoading(true);
                    let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/uploadedData?community=${community}&apiKey=${user['http://apiKey']}&page=${page}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    let json = await response.json();
                    if (json.status === 'ok') {
                        dispatch(setUserItems(json.data));
                        setUserDataLoading(false);
                    } else {
                        const { message } = json;
                        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                        if (json.error_response && json.error_response.json) {
                            const err = await json.error_response.json();
                            console.log(err);
                        }
                    }
                })();
            }
    }, [page])

    const handlePageClick = (selectedPage) => {
        const {selected} = selectedPage
        setPage(selected)
    }
    // GET DETAILED ITEM
    const getItemFromServer = async id => {
        let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/uploadedData?id=${id}&community=${community}&apiKey=${user['http://apiKey']}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    };

    const handleStreamShow = async (id, callback) => {
        const json = await getItemFromServer(id);
        if (json.status === 'ok') {
            if (json.data.length > 0) {
                const { information } = json.data[0];
                const { url } = information;
                alert(`The stream url is: ${url}`);
                if (callback) callback(); //eg. stop loader
            } else {
                const message = 'There was a problem trying to get stream data (Client)';
                enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                if (json.error_response && json.error_response.json) {
                    const err = await json.error_response.json();
                    console.log(err);
                }
            }
        } else {
            const { message } = json;
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
            if (json.error_response && json.error_response.json) {
                const err = await json.error_response.json();
                console.log(err);
            }
        }
    };

    const handleDatasetDownload = async (id, callback) => {
        const json = await getItemFromServer(id);
        if (json.status === 'ok') {
            if (json.data.length > 0) {
                const { information } = json.data[0];
                const { csv, originalname } = information;
                const filename = originalname.substring(0, originalname.length - 4) + '.csv';
                var csvBlob = new Blob([csv], { type: 'text/csv' });
                fileDownload(csvBlob, filename);
                if (callback) callback(); //eg. stop loader
            } else {
                const message = 'There was a problem trying to download data (Client)';
                enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
                if (json.error_response && json.error_response.json) {
                    const err = await json.error_response.json();
                    console.log(err);
                }
            }
        } else {
            const { message } = json;
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
            if (json.error_response && json.error_response.json) {
                const err = await json.error_response.json();
                console.log(err);
            }
        }
    };

    const handleDelete = async id => {
        let response = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/removeItem?id=${id}&community=${community}&apiKey=${user['http://apiKey']}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        let json = await response.json();
        if (json.status === 'ok') {
            dispatch(deleteUserItem(id));
        } else {
            const { message } = json;
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
            if (json.error_response && json.error_response.json) {
                const err = await json.error_response.json();
                console.log(err);
            }
        }
    };
    if (community) {
        return serverResponded ? (
            <LogoContentsTemplate logo={logo}>
                <Box marginTop={'10vh'} />
                <Hidden xsDown>
                    <Typography align={'center'} variant="h1" component="h2">
                        {title}
                    </Typography>
                </Hidden>
                <Hidden smUp>
                    <Typography align={'center'} variant="h2" component="h3">
                        {title}
                    </Typography>
                </Hidden>
                <Box marginTop={'8vh'} />
                <Typography align={'justify'}>{text}</Typography>
                <center><a href={'demo.xlsx'} style={{color: '#1aab8a'}}>download template</a></center>
                <Box marginTop={'8vh'} />
                {user ? (
                    <div>
                        <center>
                            <FancyButton
                                onClick={() => {
                                    dispatch(addFootstep(-1));
                                    history.push(steps[0].route);
                                }}>
                                <span>Add New</span>
                            </FancyButton>
                        </center>
                        <Box marginTop={'8vh'} />
                        {userDataLoading ? <Loader /> : <FileManager items={items} handleStreamShow={handleStreamShow} handleDatasetDownload={handleDatasetDownload} handleDelete={handleDelete} />}
                        <center>
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                pageCount={10}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                activeClassName={'active'}
                                breakLabel={<span>...</span>}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                            />
                        </center>
                        <Box marginTop={'6vh'} />
                    </div>
                ) : (
                    <center> Please Log In to use the app </center>
                )}
            </LogoContentsTemplate>
        ) : (
            <center>Waiting for server response</center>
        );
    } else {
        return <Redirect to={ROUTE_LOGIN} />;
    }
};

HomePage.propTypes = {
    history: PropTypes.object,
};
export default HomePage;

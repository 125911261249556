import {
    SET_ENABLE_EXTERNAL_VALIDATIONS, SET_LOADING_EXTERNAL_VALIDATIONS,
    SET_VALIDATIONS,
    SET_VALIDATIONS_BY_STEP,
} from './actionTypes';

const setValidations = validations => ({
    type: SET_VALIDATIONS,
    payload: {
        validations,
    },
});

const setLoadingExternalValidations = loadingExternalValidations => ({
    type: SET_LOADING_EXTERNAL_VALIDATIONS,
    payload: {
        loadingExternalValidations,
    },
});

const setValidationsByStep = (validations, step) => ({
    type: SET_VALIDATIONS_BY_STEP,
    payload: {
        step,
        validations,
    },
});

const setEnableExternalValidations = (enableExternalValidations) => ({
    type: SET_ENABLE_EXTERNAL_VALIDATIONS,
    payload: {
        enableExternalValidations
    }
})

export { setValidations, setValidationsByStep, setLoadingExternalValidations, setEnableExternalValidations };

import React, { useState, forwardRef, useImperativeHandle } from 'react';

import '../../../styles/AsyncValidationEditor.css';
import { useSelector } from 'react-redux';

import RemoteAutocomplete from '../RemoteAutocomplete';
import { asyncSearchForHazards } from '../../../utils/controllers/HazardsSearchController';
import { getAuth0token } from '../../../redux/selectors/mainSelectors';

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const token = useSelector(getAuth0token);

    const handleChange = hazard => {
        if (!value.originalValue) {
            setValue(hazard ? { displayValue: hazard.name, originalValue: value.displayValue, hasBeenEdited: true } : null);
        } else {
            setValue(hazard ? { displayValue: hazard.name, originalValue: value.originalValue, hasBeenEdited: true } : null);
        }
    };

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            },
        };
    });

    return (
        <RemoteAutocomplete
            noWait
            onSelect={handleChange}
            asyncFetchFunction={value => asyncSearchForHazards(token, value)}
            placeholder={value ? (value.originalValue ? value.originalValue : value.displayValue) : null}
            defaultValue={value ? value.displayValue : null}
        />
    );
});

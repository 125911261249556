import {ADD_USER_TEXT} from "../../EN_Texts";
import Button from "@material-ui/core/Button/Button";
import React from "react";

const AddUserButton = props => {
    return(
        <Button  {...props} style={{marginLeft:"15px",marginTop: "20px" }} variant="outlined">
            {ADD_USER_TEXT}
        </Button>
    )
}
export default AddUserButton
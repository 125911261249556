import React, { useState, forwardRef, useImperativeHandle } from "react";
import SearchSupplier from '../SearchSupplier';
import { useSelector } from 'react-redux';
import { getAuth0token } from '../../../redux/selectors/mainSelectors';

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const token = useSelector(getAuth0token);

    function onChangeHandler(supplier) {
        if (!value.originalValue) {
            setValue(supplier ? { displayValue: supplier.name, originalValue: value.displayValue, hasBeenEdited: true } : null);
        } else {
            setValue(supplier ? { displayValue: supplier.name, originalValue: value.originalValue, hasBeenEdited: true } : null);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            },
        };
    });

    return <SearchSupplier token={token} onSelectSupplier={onChangeHandler} selectedSupplier={value ? value.displayValue : null} originalValue={value ? value.originalValue : null} />;
});

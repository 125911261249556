import {
    INIT_MAPPINGS, SET_AUTH0_TOKEN,
    SET_COMMUNITY, SET_DATA_EDITOR_VALIDATION, SET_FOODAKAI_ENV,
    SET_MAPPING,
    SET_METADATA, SET_THEME,
    SET_UPLOAD_OBJECTS,
    SET_UPLOAD_STREAM,
    SET_UPLOAD_TYPE, SET_USER_STATS,
} from './actionTypes';

const set_foodakai_env = foodakai_env => ({
    type: SET_FOODAKAI_ENV,
    payload: {
        foodakai_env,
    },
});

const setAuth0TOken = auth0token => ({
    type: SET_AUTH0_TOKEN,
    payload: {
        auth0token,
    },
});

const setCommunity = community => ({
    type: SET_COMMUNITY,
    payload: {
        community,
    },
});

const setTheme = theme => ({
    type: SET_THEME,
    payload: {
        theme,
    },
});

const setUploadType = type => ({
    type: SET_UPLOAD_TYPE,
    payload: {
        type,
    },
});

const setUploadObjects = objects => ({
    type: SET_UPLOAD_OBJECTS,
    payload: {
        objects,
    },
});

const setUploadStream = stream => ({
    type: SET_UPLOAD_STREAM,
    payload: {
        stream,
    },
});

const initMappings = mappings => ({
    type: INIT_MAPPINGS,
    payload: {
        mappings,
    },
});

const setMapping = mapping => ({
    type: SET_MAPPING,
    payload: {
        mapping,
    },
});

const setMetadata = metadata => ({
    type: SET_METADATA,
    payload: {
        metadata,
    },
});

const setDataEditorValidation = dataEditorValidation => ({
    type: SET_DATA_EDITOR_VALIDATION,
    payload: {
        dataEditorValidation,
    },
});

const setUserStats = userStats => ({
    type: SET_USER_STATS,
    payload: {
        userStats,
    },
});

export { set_foodakai_env, setAuth0TOken, setCommunity, setTheme, setUploadType, setUploadObjects, setUploadStream, initMappings, setMapping, setMetadata, setDataEditorValidation, setUserStats };

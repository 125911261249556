import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';

export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');

    function inputHandler(e) {
        const { value } = e.target;
        setValue(value ? { displayValue: value } : null);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            },
        };
    });

    return (
        <TextField placeholder={'Enter ' + props.column.colId} value={value ? value.displayValue : null} onChange={inputHandler} ref={inputRef} />
    );
});

import React from 'react';
import Button from '@material-ui/core/Button/Button';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import HeaderContentsFooterTemplate from '../components/templates/HeaderContentsFooterTemplate';
import { footstepValidation } from '../redux/selectors/stepsSelectors';
import { ROUTE_HOME } from '../ROUTES';
import { getUserStats } from '../redux/selectors/mainSelectors';

const FinishedPage = () => {
    const footstepsValid = useSelector(footstepValidation);
    const userStats = useSelector(getUserStats);

    if (footstepsValid)
        return (
            <HeaderContentsFooterTemplate>
                <center>
                    <table style={{ marginBottom: '15px' }}>
                        <thead>
                            <th>User</th>
                            <th>Status</th>
                            <th>Details</th>
                        </thead>
                        <tbody>
                            {Object.keys(userStats).map(user => (
                                <tr key={user}>
                                    <td style={{ padding: '5px', border: '1px solid #e3e3e3' }}>{user}</td>
                                    <td style={{ padding: '5px', border: '1px solid #e3e3e3' }}>{userStats[user].status}</td>
                                    <td style={{ padding: '5px', border: '1px solid #e3e3e3' }}>{userStats[user].details}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            window.location.href = ROUTE_HOME;
                        }}>
                        Go to Home Page
                    </Button>
                </center>
            </HeaderContentsFooterTemplate>
        );
    else return <Redirect to={ROUTE_HOME} />;
};

export default FinishedPage;

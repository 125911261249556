import React, { useState, forwardRef, useImperativeHandle } from 'react';

import '../../../styles/AsyncValidationEditor.css';
import RemoteAutocomplete from '../RemoteAutocomplete';

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    const handleChange = value => {
        setValue(value ? { displayValue: value.title } : null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            },
        };
    });

    const filterHazardsAutocomplete = inputText => {
        const inputValue = inputText.trim().toLowerCase();
        const inputLength = inputValue.length;
const hazardsTaxonomy = [];
        var reg = new RegExp(
            inputValue
                .split('')
                .join('\\w*')
                .replace(/\W/, ''),
            'i',
        );
        const results = inputLength === 0 ? [] : hazardsTaxonomy.filter(hz => hz.name.match(reg));

        return {
            hits: {
                hits: results
                    .sort((a, b) => (a.name.length < b.name.length ? -1 : 1))
                    .map(res => ({
                        ['_source']: {
                            title: res.name,
                            parents: res.parents,
                        },
                    })),
            },
        };
    };

    return <RemoteAutocomplete hasHierarchy noWait onSelect={handleChange} asyncFetchFunction={filterHazardsAutocomplete} placeholder={value ? value.displayValue : null} />;
});

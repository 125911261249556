// run external async validator (hack)
import { setLoadingExternalValidations } from '../redux/actions/validationActions';

import { externalValidateTerm } from './controllers/ExternalValidationController';

export const externalValidationFields = {
    ['foodakai suppliers']: { field: 'supplier_name', index: 'supplier' },
    ['foodakai preference origins']: { field: 'origin', index: 'country' },
    ['foodakai preference products']: { field: 'product', index: 'product' },
    ['foodakai preference hazards']: { field: 'hazard', index: 'hazard' },
};

const ExternalAsyncValidator = async (token, objectType, colDefs, dataArray) => {
    if (Object.keys(externalValidationFields).includes(objectType)) {
        for (let i = 0; i < dataArray.length; i++) {
            const row = dataArray[i];
            const tempValue = row[externalValidationFields[objectType].field];
            if (tempValue) {
                const json = await externalValidateTerm(token, externalValidationFields[objectType].index, tempValue.displayValue);
                if (!json || json.length === 0 || !json[0].name) {
                    tempValue.invalid = true;
                } else if (json[0].name !== tempValue.displayValue) {
                    tempValue.originalValue = tempValue.displayValue;
                    tempValue.displayValue = json[0].name;
                    tempValue.hasBeenEdited = true;
                }
            } else {
                console.log('check here:', tempValue);
            }
        }
    }
};

export default ExternalAsyncValidator;

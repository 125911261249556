import React, {useState} from 'react';
import {setUsers} from "../../redux/actions/resourceActions";
import {useDispatch, useSelector} from "react-redux";
import {getUsers} from "../../redux/selectors/resourceSelectors";
import {TextField} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import AddUserButton from "./AddUserButton";
import DeleteUserButton from "./DeleteUserButton";

const AddUserInput = () => {
    const dispatch = useDispatch()
    const [user, setUser] = useState(null)
    const users = useSelector(getUsers)

    const handleUserChange = event =>{
        const addedUser = event.target.value.trim()
        setUser(addedUser);
    }
    const saveUser = () =>{
        dispatch(setUsers([...users,user]))
        setUser('')
    }
    const deleteUser = userToDelete =>{
        const newList = users.filter(user => user !== userToDelete)
        dispatch(setUsers(newList))
    }

    return (
        <div>
            <TextField
                required
                label="Type and add a user"
                value={user}
                onChange={handleUserChange}
                margin = "dense"
            />
            <AddUserButton onClick={saveUser}/>
            { users && (
                <Table style={{ marginTop: "20px"}} sx={{ minWidth: 650 }} aria-label="simple table">
                    <thead>
                        <td>Users</td>
                    </thead>
                    <tr>
                        {users.map(user => {
                            return(
                                <tbody>
                                    <tr>
                                        <td>{user}</td>
                                        <DeleteUserButton onClick={()=>deleteUser(user)}/>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </tr>
                </Table>
            )}
        </div>
    );
};

export default AddUserInput;

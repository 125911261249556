//steps
export const SET_FILE_STEPS = 'SET_FILE_STEPS';
export const SET_STREAM_STEPS = 'SET_STREAM_STEPS';
export const SET_CURRENT_STEPS = 'SET_CURRENT_STEPS';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_FOOTSTEPS = 'SET_FOOTSTEPS';
export const ADD_FOOTSTEP = 'ADD_FOOTSTEP';
//main
export const SET_FOODAKAI_ENV = 'SET_FOODAKAI_ENV';
export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_THEME = 'SET_THEME';
export const SET_UPLOAD_TYPE = 'SET_UPLOAD_TYPE';
export const SET_UPLOAD_OBJECTS = 'SET_UPLOAD_OBJECTS';
export const SET_UPLOAD_STREAM = 'SET_UPLOAD_STREAM';
export const INIT_MAPPINGS = 'INIT_MAPPINGS';
export const SET_MAPPING = 'SET_MAPPING';
export const SET_METADATA = 'SET_METADATA';
export const SET_DATA_EDITOR_VALIDATION = 'SET_DATA_EDITOR_VALIDATION';
export const SET_AUTH0_TOKEN = 'SET_AUTH0_TOKEN';
export const SET_USER_STATS = 'SET_USER_STATS';
//resource
export const SET_FILE = 'SET_FILE';
export const SET_CURRENT_SHEET = 'SET_CURRENT_SHEET';
export const SET_RESOURCE = 'SET_RESOURCE';
export const INIT_AGGRID = 'INIT_AGGRID';
export const SET_AGGRID_COLDEFS = 'SET_AGGRID_COLDEFS';
export const SET_AGGRID_DATA_ARRAY = 'SET_AGGRID_DATA_ARRAY';
export const SET_USERS = 'SET_USERS'
//mapping
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const ADD_SELECTED_PROPERTY = 'ADD_SELECTED_PROPERTY';
export const REMOVE_SELECTED_PROPERTY = 'REMOVE_SELECTED_PROPERTY';
//validation
export const SET_LOADING_EXTERNAL_VALIDATIONS = 'SET_LOADING_EXTERNAL_VALIDATIONS';
export const SET_VALIDATIONS = 'SET_VALIDATIONS';
export const SET_VALIDATIONS_BY_STEP = 'SET_VALIDATIONS_BY_STEP';
export const SET_ENABLE_EXTERNAL_VALIDATIONS = 'SET_ENABLE_EXTERNAL_VALIDATIONS';
//file management
export const SET_USER_ITEMS = 'SET_USER_ITEMS';
export const SET_SELECTED_USER_ITEM = 'SET_SELECTED_USER_ITEM';
export const DELETE_USER_ITEM = 'DELETE_USER_ITEM';

import {useDispatch, useSelector} from "react-redux";
import {getEnableExternalValidations} from "../../redux/selectors/validationSelectors";
import {setEnableExternalValidations} from "../../redux/actions/validationActions";
import {Checkbox} from "@material-ui/core";
import React from "react";

const ExternalValidationsCheckbox = ()=>{
    const dispatch = useDispatch();
    const enableExternalValidations = useSelector(getEnableExternalValidations)
    const handleChange = () =>{
        dispatch(setEnableExternalValidations(!enableExternalValidations))
    }
    return(
        <div>
            <Checkbox
                label='Data Validation'
                onChange={handleChange}
            /> Data Validation
        </div>
    )
}
export default ExternalValidationsCheckbox
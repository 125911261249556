//export const API_KEY = 'b1b28e6a-0894-35d7-9e22-c7eaf6da5159';
//export const PAGE_SIZE = 100;

export const searchNodeElastic = async (token, body) => {
    const response = await fetch(process.env.REACT_APP_NODE_ELASTIC_ENDPOINT + '/api/search', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
    const json = await response.json();

    return json;
};

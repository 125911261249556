import {
    SET_ENABLE_EXTERNAL_VALIDATIONS,
    SET_LOADING_EXTERNAL_VALIDATIONS,
    SET_VALIDATIONS,
    SET_VALIDATIONS_BY_STEP,
} from '../actions/actionTypes';

const initialState = {
    validations: {},
    loadingExternalValidations: 0,
    enableExternalValidations: false,
};

const validation = (state = initialState, action) => {
    switch (action.type) {
        case SET_VALIDATIONS: {
            const { validations } = action.payload;
            return {
                ...state,
                validations,
            };
        }
        case SET_LOADING_EXTERNAL_VALIDATIONS: {
            const { loadingExternalValidations } = action.payload;

            let newValue;
            if (loadingExternalValidations === '+1') newValue = state.loadingExternalValidations + 1;
            else if (loadingExternalValidations === '-1') newValue = state.loadingExternalValidations - 1;
            else newValue = loadingExternalValidations;

            return {
                ...state,
                loadingExternalValidations: newValue,
            };
        }
        case SET_VALIDATIONS_BY_STEP: {
            const { validations, step } = action.payload;
            return {
                ...state,
                validations: {
                    ...state.validations,
                    [step]: {
                        ...state.validations[step],
                        requiredFields: validations,
                    },
                },
            };
        }
        case SET_ENABLE_EXTERNAL_VALIDATIONS: {
            const { enableExternalValidations } = action.payload;
            return {
                ...state,
                enableExternalValidations
            }
        }
        default:
            return state;
    }
};

export default validation;

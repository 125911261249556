import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

import { useAuth0 } from './Auth0Wrapper';
import {getFoodakaiEnv} from "../../redux/selectors/mainSelectors";
import Text from "../atoms/Text";

const Header = ({ logo }) => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const foodakai_env = useSelector(getFoodakaiEnv);
    return (
        <Paper padding={'10px'} style={{ borderRadius: '0px' }}>
            {logo && <img alt={'logo'} src={logo} style={{ height: '50px', margin: '6px 20px' }} />}
            {foodakai_env && ['local', 'staging', 'testing'].includes(foodakai_env) && (
                <Text inline color={'red'}>{foodakai_env}</Text>
            )}
            <Box display={'inline-block'} margin={'5px'} style={{ float: 'right' }}>
                {!isAuthenticated && <Button onClick={() => loginWithRedirect({})}>Log in</Button>}

                {isAuthenticated && <Button onClick={() => logout({ returnTo: window.location.origin })}>Log out</Button>}
            </Box>
        </Paper>
    );
};
Header.propTypes = {
    logo: PropTypes.string,
};

export default Header;

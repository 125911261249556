import { searchNodeElastic } from './NodeElasticController';

// async search for my suppliers autocomplete
export const asyncSearchForHazards = (token, inputText) => {
    const options = {
        index: 'hazard',
        query: {
            bool: {
                must: [{ match: { name: inputText } }],
            },
        },
    };
    return new Promise(resolve => {
        searchNodeElastic(token, options)
            .then(res => {
                resolve(res);
                return res;
            })
            .catch(error => {
                console.log(error);
                resolve(false);
            });
    });
};

import React from 'react';
import Box from '@material-ui/core/Box/Box';
import { LinearProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import PropTypes from 'prop-types';

const LinearProgressWithLabel = (props) => {
    console.log(props.value, (props.value+1) * 100 / props.limit, props.limit)
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={(props.value+1) * 100 / props.limit} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value+1)} / ${props.limit} ${props.measure}`}</Typography>
            </Box>
        </Box>
    );
};

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    limit: PropTypes.number.isRequired,
    measure: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;

import { searchNodeElastic } from './NodeElasticController';

export const externalValidateTerm = (token, index, term) => {
    const options = {
        index,
        query: {
            bool: {
                must: [{ match: { name: term } }],
            },
        },
    };
    return new Promise(resolve => {
        searchNodeElastic(token, options).then(res => {
            resolve(res);
            return res;
        }).catch(error => {
            console.log(error);
            resolve(false);
        });
    });
};

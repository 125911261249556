import Button from '@material-ui/core/Button';
import React from 'react';
import fileDownload from "js-file-download";
import composeCSVselectedCols from '../../utils/composeCSVselectedCols';
import { formatDataForXLSX } from '../../utils/AgGridDataFormatter';
import { useSelector } from 'react-redux';
import { getColumnDefs, getDataArray } from '../../redux/selectors/resourceSelectors';
import { getUploadObjects } from '../../redux/selectors/mainSelectors';

const ProcessedFileDownloader = () => {
    const dataArray = useSelector(getDataArray);
    const columnDefs = useSelector(getColumnDefs);
    const rowData = useSelector(getDataArray);
    const object = useSelector(getUploadObjects);

    const downloadCSV = () => {
        const csv = composeCSVselectedCols(formatDataForXLSX({
            columnDefs,
            rowData,
        }));
        const csvBlob = new Blob([csv], { type: 'text/csv' });
        fileDownload(csvBlob, object + '.csv');
    };
    const downloadJSON = () => {
        const jsonBlob = new Blob([JSON.stringify(dataArray)], { type: 'text/json' });
        fileDownload(jsonBlob, object + '.json');
    };
    return (
        <div>
            <Button onClick={downloadCSV}>Download CSV</Button>
            <Button onClick={downloadJSON}>Download JSON</Button>
        </div>
    );
};

export default ProcessedFileDownloader;
